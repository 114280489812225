
import { Component, Prop, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import Loader from '@/components/loader.vue'; // @ is an alias to /src
import HRMService from "../../../services/hrm-service";

@Component({
  components: {
    Header,
    Sidebar,
    Loader,
  },
})
export default class Payroll extends Vue {
  @Prop({ type: String })
  id!: string;
  loading = false;
  // public fields = [{ key: 'employee__title', sortable: true }, { key: 'check_in', sortable: true }, { key: 'check_out', sortable: false }];
  public item: any = {}
  public currentPage = 1;
  public import_file = null
  params_date: any = {}
  import_params: any = {}
  create: any = {};
  ot: any = {};
  leave: any = {};
  late: any = {};
  emp_item: any = {};
  total_nm_allowance = 0
  allowance = 0;
  deduction = 0;
  absent_days = 0;

  onFiltered(filteredItems: any) { }

  mounted() {
    if (this.id) {
      this.get_data(this.id)

    }
  }

  get_data(params: any) {
    this.loading = true;
    HRMService.employee_payrollById(params)
      .then((response) => {
        this.item = response.data;
        this.total_nm_allowance = Number(this.item['ot']) + Number(this.item['previous_month_ot']) + Number(this.item['non_monthly_addition'])
        this.allowance = this.total_nm_allowance + Number(this.item['total_monthly_allowance'])
        this.deduction = Number(this.item['total_NM_deduction']) + Number(this.item['gosi'])
        this.absent_days = 31 - Number(this.item['days'])
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });

    HRMService.get_employee(params)
      .then((response) => {
        this.emp_item = response.data['employee'];
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  refreshList() {
  }
  getSlip() {
    let route: any = this.$router.resolve({ name: "Payroll-Slip", params: { id: this.id.toString() } });
    window.open(route.href);
  }

  open_sidebar(item: any, type: any) {
    if (type == 'ma') {
      this.$root.$emit("bv::toggle::collapse", "sidebar-variant-ma");
    }
    else if (type == 'nma') {
      this.$root.$emit("bv::toggle::collapse", "sidebar-variant-nma");
    }
    else if (type == 'pd') {
      this.$root.$emit("bv::toggle::collapse", "sidebar-variant-pd");
    }
  }

}
